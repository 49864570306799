//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { format } from 'quasar'
import { tasksStore, teamsStore } from '@/store'

const { capitalize } = format

export default {
  name: 'TaskImportanceSelector',

  components: {
    SelectorBtnToggle: () => import('./SelectorBtnToggle'),
    SelectorInput: () => import('./SelectorInput'),
    SelectorList: () => import('./SelectorList'),
  },

  props: {
    value: {
      type: Number,
      required: false,
      default: null,
    },
    displayButtonsThreshold: {
      type: Number,
      required: false,
      default: 5,
    },
    hideLabel: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideCaption: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    displayMode () {
      if (tasksStore.getters.importanceUseIcons) return 'selector-list'
      if (
        tasksStore.getters.importanceOptions.length <=
        this.displayButtonsThreshold
      ) return 'selector-btn-toggle'
      return 'selector-input'
    },
    hideLabelComputed () {
      if (this.displayMode === 'selector-list') return true // already has caption in :hint
      return this.hideLabel
    },
    label () {
      return capitalize(this.$t('glossary.importance'))
    },
    hideCaptionComputed () {
      if (
        this.displayMode === 'selector-input' || // already has caption in :hint
        this.displayMode === 'selector-list' // list is self-descriptive
      ) return true
      return this.hideCaption
    },
    caption () {
      return teamsStore.getters.currentTeam.tasksImportanceRev
        ? this.$t('tasks.importanceCaption.descending')
        : this.$t('tasks.importanceCaption.ascending')
    },
  },
}
